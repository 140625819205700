import { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import styled from "styled-components"; // Add styled-components
import Footer from "../components/Footer";
import Header from "../components/Header";
import routes from "./config";
import { Styles } from "../styles/styles";

// Flexbox layout for the entire page
const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
`;

// Content area that grows to take up available space
const ContentContainer = styled.div`
  flex: 1; /* Ensures the content area grows to fill the space */
`;

const Router = () => {
  return (
    <LayoutContainer>
      <Suspense fallback={null}>
        <Styles />
        <Header />
        <ContentContainer>
          <Switch>
            {routes.map((routeItem) => {
              return (
                <Route
                  key={routeItem.component}
                  path={routeItem.path}
                  exact={routeItem.exact}
                  component={lazy(() =>
                    import(`../pages/${routeItem.component}/index`)
                  )}
                />
              );
            })}
          </Switch>
        </ContentContainer>
        <Footer />
      </Suspense>
    </LayoutContainer>
  );
};

export default Router;
