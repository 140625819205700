const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: "/search",
    exact: true,
    component: "Search",
  },
  {
    path: "/thankyou",
    exact: true,
    component: "SuccessPage",
  },
];

export default routes;
